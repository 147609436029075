import React, { useState } from "react";
import { Link } from "gatsby";
import Seo from "../components/seo.js";
import Layout from "../components/layout";

function Benefits() {
  // States to control the moving of DIVs: bar7...bar10
  const [bar7Container, setBar7Container] = useState("hideInfoContainer");
  const [bar8Container, setBar8Container] = useState("hideInfoContainer");
  const [bar9Container, setBar9Container] = useState("hideInfoContainer");
  const [bar10Container, setBar10Container] = useState("hideInfoContainer");
  const [showText, setShowText] = useState("showshowText");
  const [isStart, setIsStart] = useState(true);
  const infoBarHeight = 800;
  const navBarHeight = 105;

  // This function handles which bar's DIV start to move depending on the scroll position
  function moveIt() {
    const appDiv = document.querySelector(".layoutContainer");
    let top = appDiv.getBoundingClientRect().top;

    setBar7Container(
      top <= 0 && top >= (infoBarHeight * 0.5 + navBarHeight) * -1
        ? "showInfoContainer"
        : "hideInfoContainer"
    );

    setBar8Container(
      top <= (infoBarHeight * 0.5 + navBarHeight) * -1 &&
        top >= (infoBarHeight * 1.5 + navBarHeight) * -1
        ? "showInfoContainer"
        : "hideInfoContainer"
    );

    setBar9Container(
      top <= (infoBarHeight * 1.5 + navBarHeight) * -1 &&
        top >= (infoBarHeight * 2.5 + navBarHeight) * -1
        ? "showInfoContainer"
        : "hideInfoContainer"
    );

    setShowText(
      top <= (infoBarHeight * 1.2 + navBarHeight) * -1 &&
        top >= (infoBarHeight * 2 + navBarHeight) * -1
        ? "showAnimatedText"
        : "hideAnimatedText"
    );

    setBar10Container(
      top <= (infoBarHeight * 2.2 + navBarHeight) * -1
        ? "showInfoContainer"
        : "hideInfoContainer"
    );
  }

  // To create a delay of displaying bar1 during the start
  if (isStart) {
    setBar7Container("hideInfoContainer");
    setIsStart(false);
    setTimeout(() => {
      setBar7Container("showInfoContainer");
    }, 500);
  }

  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    window.onscroll = moveIt;
  }

  return (
    <Layout headOneOfPage="A Moringa Oleifera fogyasztása, a Moringa levél és tea hatásai az egészségre.">
      <Seo title="A Moringa Oleifera segíthet" lang="hu" />
      <main>
        <div className="info bar7">
          <article className={`infoContainer ${bar7Container}`}>
            <header>
              <h2 className="infoTitleContainer">
                <div className="infoTitle">A JÓ ÉTREND</div>
                <div className="infoSubTitle">a legjobb orvosság !</div>
              </h2>
            </header>

            <p className="infoText">
              Az egészséges, erős testhez rengeteg jó minőségű tápanyagra van
              szükség: fehérjékre, zsírokra, szénhidrátokra, ásványi anyagokra,
              vitaminokra és nyomelemekre. Rohanó világunkban azonban nem
              táplálkozunk elég változatosan és jó minőségben. <br />A
              <b> Moringa Oleifera</b> - rendkívüli módon -, mindezeket
              tartalmazza! Különféle <b>antioxidánsok</b>, aminosavak, fontos
              vitaminok, ásványi anyagok és nyomelemek gazdagítják a sort, nem
              beszélve a rostokról.
            </p>
          </article>
        </div>

        <div className="info bar8">
          <article className={`infoContainer ${bar8Container}`}>
            <header>
              <h2 className="infoTitleContainer">
                <div className="infoTitle">A MORINGA</div>
                <div className="infoSubTitle">levele különleges !</div>
              </h2>
            </header>

            <p className="infoText">
              A <b>Moringa fa</b> összes része közül a <b>levele</b> tartalmazza
              a legtöbb tápanyagot. Ráadásul a szárított Moringa levél több
              tápanyagot tartalmaz, mint a friss, nyers levél. Ez alól csak a C
              vitamin kivétel. A szárított levelet nem csak Indiában
              fogyasztják. Viszonylag igénytelen növény, ezért Moringa
              ültetvények vannak már Ázsiában, Afrikában és Közép-Amerikában is.
              Többek között készül belőle <b>Moringa tea</b> vagy{" "}
              <b>Moringa smoothie</b> is.
            </p>
            <button className="buttons" style={{ marginLeft: "30px" }}>
              <Link className="buttonLink" to="/ingredients">
                TÁPANYAGOK
              </Link>
            </button>
          </article>
        </div>

        <div className="info bar9">
          <article className={`infoContainer ${bar9Container}`}>
            <header>
              <h2 className="infoTitleContainer">
                <div className="infoTitle">A MORINGA LEVELE</div>
                <div className="infoSubTitle">segíthet a(z)</div>
              </h2>
            </header>

            <p className="infoText">
              <ol>
                <li className={showText} id="delay1">
                  káros szabad gyökök elleni küzdelemben...
                </li>
                <li className={showText} id="delay2">
                  energiaszint fenntartásában...
                </li>
                <li className={showText} id="delay3">
                  gyulladások csökkentésében...
                </li>
                <li className={showText} id="delay4">
                  immunrendszer erősítésében...
                </li>
                <li className={showText} id="delay5">
                  öregedés lassításában...
                </li>
                <li className={showText} id="delay6">
                  vérnyomás csökkentésében...
                </li>
                <li className={showText} id="delay7">
                  vércukorszint csökkentésében...
                </li>
                <li className={showText} id="delay8">
                  jó emésztésben...
                </li>
                <li className={showText} id="delay9">
                  erőnlét fenntartásában...
                </li>
                <li className={showText} id="delay10">
                  pihentető alvásban...
                </li>
                <li className={showText} id="delay11">
                  méregtelenítésben...
                </li>
              </ol>
            </p>
          </article>
        </div>

        <div className="info bar10">
          <article className={`infoContainer ${bar10Container}`}>
            <header>
              <h2 className="infoTitleContainer">
                <div className="infoTitle">KOFFEIN MENTES</div>
                <div className="infoSubTitle">Moringa energia !</div>
              </h2>
            </header>

            <p className="infoText">
              A legjobban elterjedt élénkítő szerünk a kávé és a tea. Azonban a
              koffeinnek és teinnek számos rossz hatása van, amelyeket viszont a{" "}
              <b>Moringa levélből készült tea vagy smoothie nem produkál</b>,
              mégis <b>segít az energiaszint növelésében</b>. A koffein gyorsan
              emeli meg az energiaszintet, a Moringa teában és smoothieban
              található tápanyagok lassan, fokozatosan. A kávéval szemben a
              <b> Moringa levél</b> nem okoz heves szívdobogást, izzadást,
              feszültség-érzetet, vérnyomás ingadozást, majd a hatás elmúltával
              kimerültség érzetet.
            </p>
          </article>
        </div>
      </main>
    </Layout>
  );
}

export default Benefits;
